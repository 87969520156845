/* Sidebar Styling */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background-image: linear-gradient(to top, #37305E, #FF4177);
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    scrollbar-width: none;
  }

  body::-webkit-scrollbar, body::-webkit-scrollbar-button { display: none; } 
  
  .sidebar .logo {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sidebar .logo img {
    max-width: 150px;
    border-radius: 500px;
    border: 3px solid white;
  }
  
  .sidebar a {
    padding: 15px;
    text-align: left;
    text-decoration: none;
    font-size: 18px;
    color: white;
    display: block;
  }
  
  .sidebar a:hover {
    background-color: #575757;
  }
  
  