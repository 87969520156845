.topbar{
    width: 90%;
    margin: auto;
    display: flex;
    gap: 30px;
}

.topbar div{
    width: 200px;
    height: 45px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-image: linear-gradient(to left, #37305E, #FF4177);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.analytics-container{
    width: 90%;
    margin: auto;
    height: 90vh;
    overflow: auto;
    border: 2px solid #37305E;
    background-color: #333;
    text-align: left;
    padding: 40px;
}

.analytics-para{
    display: flex;
    align-items: center;
    font-size: 22px;
    gap: 10px;
    color: rgb(219, 219, 219);
}

.icons-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.icons-wrapper{
    text-align: center;
    margin-top: 30px;
    padding: 40px;
    background-color: #140f33;
    border: 2px solid #FF4177;
    border-radius: 20px;
}

.icons-container div{
    background-image: linear-gradient(to top, #37305E, #FF4177);
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
}

.graph-container{
    text-align: center;
    margin-top: 30px;
    padding: 40px;
    background-color: #140f33;
    border: 2px solid #FF4177;
    border-radius: 20px;
}



.page-views{
    width: 20px;
    height: 20px;
    background-color: #FF5733;
    display: inline-block;
}

.page-impressions{
    width: 20px;
    height: 20px;
    background-color: #33A1FF;
    display: inline-block;
}

.post-engagement{
    width: 20px;
    height: 20px;
    background-color: #28A745;
    display: inline-block;
}

.graph-tags{
    text-align: left;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px;
}